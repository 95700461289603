[data-bs-theme='dark'] {
  --navbar-bg-color: #000000; /* Dark background for the navbar */
  --bs-body-bg: rgb(14, 14, 14)!important; /* Dark background for the body */
  --text-color: #424242; /* White text color */
}

/* Apply the colors globally */
body {
  background-color: var(--bs-body-bg);
  color: var(--text-color);
  font-family: 'Kanit', sans-serif;
}
* { /* Apply to specific elements if needed */
  font-family: 'Kanit', sans-serif;
}

.navbar {
  background-color: var(--navbar-bg-color);
}

.navbar .nav-link {
  color: var(--bs-nav-link-color); /* Ensures nav links are visible */
  font-weight: 300;
}

.navbar .nav-link:hover {
  color:  var(--bs-navbar-hover-color); /* Lighter color on hover */
}